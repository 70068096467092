var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('h1',[_vm._v("Deliveries")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.deliveries,"no-data-text":"No deliveries found"},scopedSlots:_vm._u([{key:"item.identifier_full",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'module-paw-deliveries-individual',
                params: { deliveryId: item.id },
              }}},[_vm._v(_vm._s(item.identifier_full))])]}},{key:"item.customer_full",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'module-paw-customers-individual',
                params: { customerId: item.parent.customer.id },
              }}},[_vm._v(_vm._s(item.customer_full))]),_vm._v("  "),_c('span',{staticClass:"caption"},[_vm._v("("+_vm._s(item.parent.dog_name)+")")])]}},{key:"item.cost",fn:function({ item }){return [_vm._v("£"+_vm._s(item.cost))]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","label":"","color":_vm.getDeliveryStatusColor(item.delivery_status)}},[_vm._v(_vm._s(_vm.getDeliveryStatusLabel(item.delivery_status)))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                    name: 'module-paw-deliveries-individual',
                    params: { deliveryId: item.id },
                  }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Delivery")])])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }