<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col>
          <h1>Deliveries</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters></v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="deliveries"
            no-data-text="No deliveries found"
          >
            <template v-slot:item.identifier_full="{ item }">
              <router-link
                :to="{
                  name: 'module-paw-deliveries-individual',
                  params: { deliveryId: item.id },
                }"
                >{{ item.identifier_full }}</router-link
              >
            </template>
            <template v-slot:item.customer_full="{ item }">
              <router-link
                :to="{
                  name: 'module-paw-customers-individual',
                  params: { customerId: item.parent.customer.id },
                }"
                >{{ item.customer_full }}</router-link
              >&nbsp;
              <span class="caption">({{ item.parent.dog_name }})</span>
            </template>
            <template v-slot:item.cost="{ item }">£{{ item.cost }}</template>
            <template v-slot:item.status="{ item }">
              <v-chip
                small
                label
                :color="getDeliveryStatusColor(item.delivery_status)"
                >{{ getDeliveryStatusLabel(item.delivery_status) }}</v-chip
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    :to="{
                      name: 'module-paw-deliveries-individual',
                      params: { deliveryId: item.id },
                    }"
                  >
                    <v-icon x-small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View Delivery</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { paw } from "../../mixins/";

export default {
  mixins: [paw],

  data() {
    return {
      tableHeaders: [
        { text: "Delivery Ref", value: "identifier_full" },
        { text: "Customer", value: "customer_full" },
        { text: "Payment Date", value: "formatted_dates.process_date" },
        {
          text: "Estimated Delivery Date",
          value: "formatted_dates.estimated_delivery",
        },
        { text: "GA Ref", value: "ga_ref" },
        { text: "Cost", value: "cost" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", align: "right" },
      ],
    };
  },

  computed: {
    deliveries() {
      let deliveries = this.$store.state.paw["deliveries"];

      deliveries.map((item) => {
        item.identifier_full = item.parent.identifier + item.identifier;
        item.customer_full = item.parent.customer.full_name;
      });

      return deliveries;
    },
  },
};
</script>
